.loading.cover-content {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.loading.cover-page {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.loading .ant-spin {
    color: #3e79f7 !important;
}

.only-title .ant-select-selection-item .ant-list-item-meta-avatar {
    display: none !important;
}

.only-title .ant-select-selection-item .ant-list-item-meta-description {
    display: none !important;
}

.only-title .ant-select-selection-item .ant-list-item {
    padding: 10px 0px !important;
}

.addProductBox {
    position: relative !important;
}

.addProductBox .removeProductButton {
    position: relative !important;
    top: 0 !important;
}
.ant-select-dropdown{
    overflow: visible;
}
.calendar {
    min-width: 350px;
    border-radius: 3px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (max-width: 767px) {
    .addProductBox .removeProductButton {
        position: absolute !important;
        top: -20px !important;
        right: 0 !important;
    }
}

.flexBetween {
    display: flex !important;
    justify-content: space-between !important;
}

.subText {
    font-size: 14px !important;
    color: #2a2c2e !important;
    margin: 0 !important;
}

.midText {
    font-size: 12px !important;
    color: #72849a !important;
}

.midText label {
    font-size: 12px !important;
    color: #72849a !important;
}

.subText table {
    font-size: 14px !important;
    color: #2a2c2e !important;
}

.midTextDark {
    font-size: 12px !important;
    color: #535a64 !important;
}

.customButtonBlue {
    width: 70px !important;
    height: 24px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    color: #fff !important;
    background-color: #1890ff !important;
    border: solid 1px #1890ff !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.customLineButton {
    width: 70px !important;
    height: 24px !important;
    border-radius: 3px !important;
    line-height: 10px !important;
    font-size: 12px !important;
    color: #1890ff !important;
    background-color: transparent !important;
    border: none !important;
    text-align: center !important;
}

.customBoxButton {
    width: 21px !important;
    height: 21px !important;
    background-color: transparent !important;
    color: #1890ff !important;
    margin-left: 10px !important;
    font-size: 9px !important;
    border-radius: 3px !important;
    border: none !important;
}

.customButtonGreen {
    width: 70px !important;
    height: 24px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    color: #fff !important;
    background-color: #6ad48d !important;
    border: solid 1px #6ad48d !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.customButtonGreen:hover {
    background-color: #3f9b5d !important;
    border: solid 1px #3f9b5d !important;
}

.customButtonGrey {
    width: 70px !important;
    height: 24px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    color: #333 !important;
    background-color: #eee !important;
    border: solid 1px #eee !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.customSmallTag {
    /* width: 70px !important;
    height: 24px !important; */
    display: inline-block !important;
    border-radius: 3px !important;
    font-size: 11px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.linkText {
    color: #1677ff !important;
    cursor: pointer !important;
}

/* Custom Scrollbar  */

.scrollBox {
    width: 100% !important;
    height: auto !important;
    overflow-y: auto
}

/* width */
.scrollBox::-webkit-scrollbar {
    width: 0px !important;
}

/* Track */
.scrollBox::-webkit-scrollbar-track {
    background: #ccc !important;
}

/* Handle */
.scrollBox::-webkit-scrollbar-thumb {
    background: #45a7c2 !important;
}

/* Handle on hover */
.scrollBox::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

/* Image Hover  */

.imageHover {
    position: relative !important;
    transition: .3s ease-in-out !important;
    width: 100% !important;
    height: 100px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.imagesHoverButton {
    /* position: absolute !important;
    bottom: 0px !important;
    right: 0px !important;
    width: 100% !important;
    height: 100% !important; */
    /* display: flex !important;
    align-items: center !important;
    justify-content: center !important; */
    /* gap: 3px !important; */
    /* background-color: rgba(0, 0, 0, 0.42) !important; */
}

/* .imagesHoverButton button {
    color: #d0cfcf !important;

} */

/* .imagesHoverButton button{
    font-size: 12px;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
} */


.imagesHoverButton .ant-btn-sm, .editButton .ant-btn-sm {
    height: 20px;
    padding: 3px 7px;
    font-size: 10px;
    border-radius: 3px;
}

.tinyIcon {
    width: 24px !important;
    height: 24px !important;
    background-color: transparent !important;
    border: none !important;
    line-height: 24px !important;
    padding: 5px !important;
}

/* .imagesHoverButton button:hover {
    background-color: transparent !important;
    color: #fff !important;
} */

.inline_flex {
    display: flex !important;
    align-items: center !important;
    margin: 0 !important;
}

.noBreak {
    white-space: nowrap !important;
}
.doBreak {
    white-space: break-spaces;
}
.ant-table-cell{    
    white-space: nowrap;
 }
.app-content:has(.custom) {
    padding: 0 !important;
}

.ant-form-item.no-bottom-margin {
    margin-bottom: 4px !important;
}

.custom {
    background-color: #fff !important;
    height: 100% !important;
}

.custom .ant-card {
    border-radius: 0 !important;
}

.userInfo {
    padding: 15px 10px 15px 24px !important;
    display: flex !important;
    align-items: center !important;
    background-color: #fff !important;
    border: solid 1px #eee !important;
        height: 70px;
}

.subTitle {
    padding: 15px 10px 15px 0px !important;
    margin: 0 !important;
        display: inline;
}

.textCenter {
    text-align: center !important;
}

.colorDark {
    color: #666 !important;
}

.colorWhite {
    color: #fff !important;
}

.flexer {
    display: flex !important;
    flex-direction: column !important;
}

.subscriptionSider {
    background: #fff !important;
    height: 80vh !important;
    overflow-y: auto !important;
    width: 200px !important;
}

.subscriptionSider::-webkit-scrollbar {
    width: 1px !important;
}

.subscriptionSider.ant-layout-sider-collapsed .mobile{
display: none;
}
.subscriptionMenuIcon{
    font-size: 16px;
    width: 64px;
    height: 64px;
}
.subscriptionMenuIcon:hover{
    background-color: transparent !important;
}

/* .sliderGallery{
    width: 100% !important;
    height: 100px !important;
} */
.sliderGallery .ant-image {
    height: 100% !important;
}

.sliderGallery .ant-image img {
    height: 100px !important;
    object-fit: cover !important;
}

.newArrivalCategory {
    position: absolute !important;
    top: 4px !important;
    right: 4px !important;
    margin: 0 !important;
    background-color: #fff !important;
    padding: 1px 5px 0px 5px !important;
    font-size: 10px !important;
    border-radius: 2px !important;
}

.productTable .ant-table-column-title {
    white-space: nowrap !important;
}

.newSliderUpload .ant-upload.ant-upload-select {
    display: block !important;
}

.customTypo h4 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: #333 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.customTypo h5 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: #736b6b !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

/* .productDescription{
    text-overflow:ellipsis !important;
  overflow: hidden !important;
} */

.infoBox{
        border: solid 1px #e7e7e7;
    border-radius: 5px;
        height: 100%;
}
.infoHead {
    padding: 8px 14px;
    background-color: rgb(145 223 125 / 44%);
    border-radius: 5px 5px 0 0;
}

.infoHead .subText {
    font-size: 13px !important;
}
.infoBody{
    padding: 10px;
}
.infoLine {
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.infoLine .midText{
    font-size: 11px !important;
}
.infoLine .midText.font-weight-semibold{
    width: 55%;
}
.infoLine .midText.rightText{
    text-align: right;
}


.timelineHead{
    padding: 8px 14px;
    background-color: rgb(145 223 125 / 44%);
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
        margin-bottom: 20px;
}
.indicatorPanel{
        display: flex;
    align-items: center;
    gap: 10px;
}
.indicatorPanel .colorDot{
    width: 10px;
    height: 15px;
    border-radius: 50px;
}

.formTitle {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.formTitleText {
    font-size: 12px;
    color: #72849a;
    text-transform: uppercase;
}
.timelineHeadTop{
    padding: 8px 14px;
    background-color: rgb(145 223 125 / 44%);
    border-radius: 5px 5px 0 0;
    display: flex;
}
.timelineHeadTop .subText{
    width: 50%;
}
.picUpload .ant-btn-icon-only.ant-btn-sm {
    padding: 0px 0;
}
.timelineStatus{
display: flex;
}
.timelineStatus .timelineTime{
    width: 45%;
}
.timelineBody{
    max-height: 400px;
    overflow: auto;
}

/* 👉 Global changes that impact overall web pages  */

body::-webkit-scrollbar{
  width: 5px;
}
.app-header {
    position: relative;
}
.logo {
    position: fixed;
        background-color: #fff;
    z-index: 9999;
}
.app-content {
    margin-top: 10px;
    /* padding: 12px; */
}
.app-page-header {
    padding: 25px;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    overflow: hidden;
    z-index: 999;
    height: 70px;
    background-color: #fff;
    box-shadow: 0 1px 4px -1px rgba(0,0,0,.15);
}
.ant-table-pagination.ant-pagination {
    margin: 16px 5px 0 5px;
}
/* .ant-card-body {
    padding: 12px;
} */

.ant-btn.customSmallButton{
    height: 28px;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 500;
}
.ant-tag.customBlockTag {
    display: block;
    text-align: center;
    max-width: 90%;
    padding: 0 10px;
}

/* 👉 Core Component  */

.app-content:has(.compactBox),.app-content:has(.compactBoxTab){
    padding: 5px;
}
.ant-card-body:has(.compactBox){
    padding: 5px;
}
.compactBoxTab .ant-card-body{
    padding: 10px;
}
.compactBox .ant-tabs-tab {
    margin-right: 24px;
}
.compactBoxTab .ant-tabs-tab .anticon {
    margin-right: 5px;
}
.compactBoxTab .ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
    padding: 16px 0;
    font-size: 14px;
    font-weight: 500;
}
.compactBoxTab .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 28px 0 0;
}
.compactBoxCreate .ant-radio-button-wrapper {
    margin: 20px 0 20px 0;
    padding: 0 60px;
}
.ant-table:has(.compactBox){
    font-size: 12px;
}

/* ============================ */

.castomMarginButton{
    margin-top: 27px;
}

.table-responsive.tableFit{
    height: 70vh;
    overflow-y: auto;
}
.table-responsive.tableFit .ant-table-thead{
    position: sticky;
    top: 0;
    z-index: 9;
}

.table-responsive::-webkit-scrollbar{
   width: 5px; 
}
card.cardFit{
padding: 12px;
}
/* .customTab .ant-tabs.ant-tabs-top.ant-tabs-large.ant-card-head-tabs{
    position: sticky;
    top: 0;
    overflow: hidden;
} */

.timelineBody .ant-timeline-item {
    padding-top: 5px;
}
.flexTimelineAction{
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.imageWrap{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.sliderModalImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.w-35{
    width: 35%;
}

/* SECTION: Background Color  */

.bg_pearl {
    background-color: #f5eff6 !important;
}

/* 👉 21st August  */
.spanBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.spanFont{
    font-size: 10px;
}
.userInfoWrapBg{
    padding: 8px;
    background-color: rgb(235 216 236 / 39%);
    margin-bottom: 10px;
    border-radius: 3px;
}
.userInfoWrapBg h4{
    font-size: 12px;
}
.userInfoWrapBg h6{
    font-size: 10px;
    font-weight: 500;
}
.blockBox{
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
.blockBox h6{
    font-size: 15px;
    font-weight: 500;
}
.blockBoxPurple{
    background-color: rgb(250 205 246 / 46%);
}
.blockBoxOrage{
    background-color: rgba(239, 214, 169, 0.46);   
}
.blockBoxGreen{
    background-color: rgba(178, 244, 234, 0.46);   
}
.blockBoxRed{
    background-color: rgba(244, 178, 178, 0.46);   
}
.fullTag{
    width: 90px;
    height: 90px;
    line-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flexBlock{
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    padding: 10px;
    height: 100%;
}
.flexBlock span{
    font-size: 12px;
}
.flexColumn{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: baseline;
}
.statusOnAvatar {
    position: absolute;
    top: 10px;
    right: -9px;
    border-radius: 0 15px 15px 0;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.statusOnEdit {
        position: absolute;
    bottom: 0px;
    left: 41px;
    width: 35px;
    height: 35px;
    text-align: center;
    padding: 0;
    border-radius: 5px;
}
.bottomBoderred{
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    line-height: 50px;
}
.flexButtonRow{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}
.statusBlock .colorStatus{
    font-size: 12px;
    text-transform: capitalize;
}

/* 👉 Media Query */

@media only screen and (max-width: 768px){

.footer {
    text-align: center;
}
.logo {
    position: relative;
}
.compactBoxCreate .ant-radio-button-wrapper {
    padding: 0 30px;
}
.w-35{
    width: 100%;
}
.ant-form-item {
    margin: 0 0 10px;
}
.popupImage{
    margin: 5px 0;
}

}